.centermyform {
  display: flex;
  justify-content: center;
  align-items: center;
}
* {
  margin: 0;
  padding: 0;
}
.MainPages {
  height: "100%";
  background-position-x: center;
  background-position-y: center;
}
